// Core modules
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

export class RouteReusableStrategy extends RouteReuseStrategy {

    /**
     * @function shouldDetach
     * @description
     * @public
     * @param {ActivatedRouteSnapshot} route
     * @returns {boolean}
     */
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    /**
     * @function store
     * @description
     * @public
     * @param {ActivatedRouteSnapshot} route
     * @param {DetachedRouteHandle} detachedTree
     * @returns {void}
     */
    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

    /**
     * @function shouldAttach
     * @description
     * @public
     * @param {ActivatedRouteSnapshot} route
     * @returns {boolean}
     */
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    /**
     * @function retrieve
     * @description
     * @public
     * @param {ActivatedRouteSnapshot} route
     * @returns {DetachedRouteHandle}
     */
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return null;
    }

    /**
     * @function shouldReuseRoute
     * @description
     * @public
     * @param {ActivatedRouteSnapshot} future
     * @param {ActivatedRouteSnapshot} curr
     * @returns {boolean}
     */
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return (future.routeConfig === curr.routeConfig) || future.data.reuse;
    }

}
