// Core modules
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import {APP_INITIALIZER} from '@angular/core';

import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en-US-POSIX';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHe from '@angular/common/locales/he';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';

// Third-party modules
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AlertModule} from 'ngx-bootstrap';
import {BowserModule, BowserService} from 'ngx-bowser';

// Internal modules
import {CoreModule} from '@app/core/core.module';
import {LoginModule} from '@app/login/login.module';
import {ThanksPageModule} from '@app/thanks-page/thanks-page.module';
import {HomeModule} from '@app/home/home.module';
import {SharedModule} from '@app/shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppConfig} from '@app/core/app.config';

// Internal components
import {AppComponent} from './app.component';
import {GdprComponent} from './login/gdpr/gdpr.component';

// Internal services
import {HeaderService} from '@app/shell/header/header.service';
import {PollService} from '@app/shared/service/poll.service';
import {UserService} from '@app/shared/service/user.service';
import {SessionService} from '@app/shared/service/session.service';
import {RelatedService} from '@app/shared/service/related.service';
import {AttendeesService} from '@app/shared/service/attendees.service';
import {VersionService} from '@app/shared/service/version.service';
import {UtilService} from '@app/shared/service/util.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {CustoService} from '@app/shared/service/custo.service';
import {SessionStatusService} from '@app/shared/service/sessionStatus.service';

registerLocaleData(localeBg, 'bg');
registerLocaleData(localeCs, 'cs');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEl, 'el');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'fr');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeHe, 'he');
registerLocaleData(localeHu, 'hu');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localePl, 'pl');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeRo, 'ro');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeSk, 'sk');
registerLocaleData(localeTh, 'th');
registerLocaleData(localeTr, 'tr');
registerLocaleData(localeUk, 'uk');
registerLocaleData(localeVi, 'vi');
registerLocaleData(localeZh, 'zh');

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule.forRoot(),
        AlertModule.forRoot(),
        CoreModule,
        LoginModule,
        ThanksPageModule,
        HomeModule,
        SharedModule.forRoot(),
        AppRoutingModule,
        BowserModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        HeaderService,
        PollService,
        UserService,
        SessionService,
        RelatedService,
        AttendeesService,
        VersionService,
        UtilService,
        BowserService,
        BrowserService,
        SdkService,
        CustoService,
        SessionStatusService,
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig],
            multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        GdprComponent
    ]
})
export class AppModule {
}

export function initConfig(config: AppConfig) {
    return () => config.load();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/translations/', '.json');
}
