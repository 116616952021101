// Core modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Internal models
import {Config, isConfigValid} from '@app/shared/models/config';

@Injectable()
export class AppConfig {

    private config: any = null;
    private environment: string;

    constructor(
        private http: HttpClient
    ) {}

    public load() {
        return new Promise((resolve, reject) => {
            this.http.get('./env/config.json').subscribe(
                (config: Config) => {
                    if (isConfigValid(config)) {
                        this.config = config;
                        resolve(true);
                    } else {
                        console.error('config.json file does not match expected format. Please, check properties.');
                        resolve(false);
                    }
                },
                (error) => {
                    console.error('config.json file not found in env/ folder.');
                });
        });
    }

    public get(key: string) {
        return this.config && this.config[key];
    }

    public env() {
        return this.environment;
    }
}
