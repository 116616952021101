// Core modules
import {Directive, ElementRef, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

    /**
     * Data members
     */
    @Output() public clickOutside = new EventEmitter<MouseEvent>();

    /**
     * @function constructor
     * @param {ElementRef} _elementRef
     */
    constructor(
        private _elementRef: ElementRef
    ) {}

    /**
     * @function onClick
     * @description
     * @public
     * @param {MouseEvent} event
     * @param {HTMLElement} targetElement
     * returns {void}
     */
    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutside.emit(event);
        }
    }
}
