// Core modules
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    /**
     * Data members
     */
    @Input() isLoading = false;
    @Input() message: string;

    /**
     * @function constructor
     */
    constructor() {}

}
