import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap';

import { SharedModule } from '@app/shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { ComponentWrapperComponent } from '@app/shared/components/componentWrapper.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    AlertModule.forRoot(),
    LoginRoutingModule,
    SharedModule
  ],
  declarations: [
    LoginComponent,
    GdprComponent,
    ComponentWrapperComponent
  ]
})
export class LoginModule { }
