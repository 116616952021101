// Core modules
import {HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

// Third-party modules
import {Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor {

    /**
     * @function constructor
     * @param _authService
     */
    constructor(
        private _authService: AuthenticationService,
        public flashService: FlashService,
        public translateService: TranslateService,
    ) {}

    /**
     * @function intercept
     * @description
     * @public
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._authService.credentials) {
            return next.handle(request);
        }
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this._authService.credentials.access_token}`
            }
        });

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

                    if (error.status != 401) {
                        if (error.status === 0) {
                            this.translateService.get('connectivity_issue').subscribe(
                                ((txt: string) => {
                                    this.flashService.error(txt, 99999, false);
                                })
                            );
                        }
                        else{
                            this.translateService.get('errorcode_500', { code: error.status })
                                .subscribe((txt: string) => {
                                    this.flashService.warning(txt, 99999, true);
                                });
                        }
                    }
                    return throwError(errorMessage);
                })
            );
    }

}
