// Internal interfaces
import {Message} from '@app/core/messaging/message';
import {statePlayPause} from '@app/core/messaging/session-state';
import {LoadSlide} from '@app/core/messaging/load-slide';

export class SessionPlayPauseState extends Message {

    state: statePlayPause;
    currentSlide: LoadSlide;

}
