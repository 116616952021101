// Core modules
import {AfterContentInit, Component, ComponentFactoryResolver, ComponentRef, Input, OnChanges, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
    selector: 'app-component-wrapper',
    template: `<div #target></div>`
})
export class ComponentWrapperComponent implements AfterContentInit, OnDestroy, OnChanges {

    /**
     * Data members
     */
    @ViewChild('target', { read: ViewContainerRef }) target: any;
    @Input() component: any;
    private cmpRef: ComponentRef<any>;
    private isViewInitialized: boolean;

    /**
     * @function constructor
     * @param {ComponentFactoryResolver} resolver
     */
    constructor(
        private resolver: ComponentFactoryResolver
    ) {}

    /**
     * @function _updateComponent
     * @description
     * @private
     * @returns {void}
     */
    private _updateComponent(): void {
        if (!this.isViewInitialized) {
            return;
        }
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
        const factory = this.resolver.resolveComponentFactory(this.component);
        this.cmpRef = this.target.createComponent(factory);
        this.cmpRef.instance.isLoaded = false;
    }

    /**
     * @function ngOnChanges
     */
    ngOnChanges() {
        this._updateComponent();
    }

    /**
     * @function ngAfterContentInit
     */
    ngAfterContentInit() {
        this.isViewInitialized = true;
        this._updateComponent();
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
    }
}
