// Internal interfaces
import {Message} from '@app/core/messaging/message';

export class SlideLoadedEvent extends Message {

    presentationIdentifier: string;
    sequenceIdentifier: string;
    slideName: string;

}
