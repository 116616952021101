// Core modules
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/Observable';
import {ReplaySubject} from 'rxjs/ReplaySubject';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {ThanksPageService} from '@app/thanks-page/thanks-page.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {SessionStatusService} from '@app/shared/service/sessionStatus.service';


// Internal interfaces
import {ChatMessage} from '@app/core/messaging/chat-message';

// Global variable declaration
declare const NoSleep: any;
declare const ActiveXObject: any;

@Injectable()
export class UtilService {

    /**
     * @function constructor
     * @param {AuthenticationService} _authService
     * @param {Router} _router
     * @param {ActivatedRoute} _route
     * @param {BrowserService} _browserService
     * @param {ThanksPageService} _thanksPageService
     * @param {TranslateService} translateService
     * @param {SdkService} _sdkService
     * @param {SessionStatusService} _sessionStatusService
     */
    constructor(
        private _authService: AuthenticationService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _browserService: BrowserService,
        private _thanksPageService: ThanksPageService,
        private translateService: TranslateService,
        private _sdkService: SdkService,
        private _sessionStatusService: SessionStatusService
    ) {}

    /**
     * @function _doLogout
     * @description
     * @private
     * @param {string} path
     * @returns {void}
     */
    public _doLogout(path: string = '/exit'): void {
        const username = this._authService.credentials && this._authService.credentials.username;
        // setTimeout to avoid username to be null
        // Even if credential is destroyed after calling authService.logout(), it seems to be null before... Why?
        setTimeout(() => {
            this._authService.logout().subscribe(() => {
                // Telling JS SDK we are exiting application
                this.sendExitNotification();

                // For external HTTP redirection
                if (path === '/exit') {
                    const attendeeSurveyUrl: string = this._sessionStatusService.getAttendeeSurvey(username);
                    // OCE survey fulfilled
                    if (attendeeSurveyUrl) {
                        this._thanksPageService.redirect(attendeeSurveyUrl);
                    }
                    // Custo survey fulfilled
                    else if (this._thanksPageService.hasExternalRedirection) {
                        this._thanksPageService.redirect();
                    }
                    // If no custo URL, redirecting to the exit page
                    else {
                        this._router.navigate([path], { replaceUrl: true });
                    }
                }
                // For internal path redirection
                else {
                    this._router.navigate([path], { replaceUrl: true });
                }
                this._authService.setCredentials();
            });
        }, 0);
    }

    /**
     * @function sendExitNotification
     * @description
     * @public
     * @returns {void}
     */
    public sendExitNotification() {
        this._sdkService.sdk && this._sdkService.sdk.getPlayerJS().eventDispatcher({type: 'exit-notification'});
    }

    /**
     * @function getNoSleepJs
     * @description
     * @public
     * @returns {any}
     */
    public getNoSleepJs(): any {
        return new NoSleep();
    }

    /**
     * @function isAcrobatReaderInstalled
     * @description
     * @public
     * @returns {boolean}
     */
    public isAcrobatReaderInstalled(): boolean {
        const isIE = this._browserService.isIE();

        function getActiveXObject(name: string) {
            try {
                return new ActiveXObject(name);
            } catch (e) {
            }
        }

        function getPlugin(name: string) {
            for (const key in navigator.plugins) {
                const plugin = navigator.plugins[key];
                if (plugin.name === name) {
                    return plugin;
                }
            }
        }

        function getPdfPlugin() {
            return function () {
                if (isIE) {
                    return getActiveXObject('AcroPDF.PDF') ||
                        getActiveXObject('PDF.PdfCtrl');
                } else {
                    return getPlugin('Adobe Acrobat') ||
                        getPlugin('Chrome PDF Viewer') ||
                        getPlugin('WebKit built-in PDF');
                }
            }();
        }

        return !!getPdfPlugin();
    }

    /**
     * @function setUserChatNickName
     * @description
     * @public
     * @param{ChatMessage} message
     * @returns {boolean}
     */
    public setUserChatNickName(message: ChatMessage): Observable<string> {
        const subject = new ReplaySubject<string>();
        if (message.user) {
            if (message.user.first_name && message.user.last_name) {
                subject.next(message.user.first_name + ' ' + message.user.last_name);
            } else if (message.user.first_name) {
                subject.next(message.user.first_name);
            } else if (message.user.last_name) {
                subject.next(message.user.last_name);
            } else {
                this.translateService.get('guest').subscribe((text: string) => {
                    subject.next(text);
                });
            }
        } else {
            this.translateService.get('guest').subscribe((text: string) => {
                subject.next(text);
            });
        }
        return subject.asObservable();
    }

}
