import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>;
    @Input() progress: number;
    @Input() width: number;
    @Input() height: number;
    @Input() color?: string

    private ctx: CanvasRenderingContext2D;
    private pause: boolean;

    constructor() {
    }

    ngOnInit() {
        this.ctx = this.canvas.nativeElement.getContext('2d');
        this.canvas.nativeElement.width = this.width;
        this.canvas.nativeElement.height = this.height;

        this.ctx.fillStyle = this.color || '#1BB218';
        this.ctx.fillRect(0, 0 , this.width, this.height);
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    private start(): void {
        this.pause = false;
        this.draw();
    }

    private stop(): void {
        this.pause = true;
    }

    private draw(): void {
        if (this.pause) {
            return;
        }

        requestAnimationFrame(this.draw.bind(this));
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.fillRect(0, 0, this.progress || 0, this.height);
    }

}
