// Internal interfaces
import {Message} from '@app/core/messaging/message';

export class SurveyNotification extends Message {

    status: string;
    sessionId: number;
    id: number;
    questionID: number;

}
