// Core modules
import {Injectable} from '@angular/core';

// Internal services
import {CustoService} from '@app/shared/service/custo.service';

@Injectable()
export class ThanksPageService {

    public CUSTO: any = {};
    private _hasExternalRedirection: boolean;

    /**
     * @function constructor
     * @description
     * @param {CustoService} _custoService
     */
    constructor(
        private _custoService: CustoService
    ) {
        this._initCusto();
    }

    /**
     * @function hasExternalRedirection
     * @description Getter
     * @public
     * @returns {boolean}
     */
    public get hasExternalRedirection() {
        return this._hasExternalRedirection;
    }

    /**
     * @function redirect
     * @description
     * @public
     * @param {string} url
     * @returns {void}
     */
    public redirect(url: string = null): void {
        let redirectUrl: string;
        if (url) {
            redirectUrl = url;
        } else if (this.CUSTO['thanksPageFormUrl']) {
            redirectUrl = this.CUSTO['thanksPageFormUrl'];
        } else if (this.CUSTO['thanksPageRedirectUrl']) {
            redirectUrl = this.CUSTO['thanksPageRedirectUrl'];
        }
        window.location.href = redirectUrl;
    }

    /**
     * @function _initCuto
     * @description
     * @private
     * @returns {void}
     */
    private _initCusto(): void {
        this._custoService.custoLoadedSubject.subscribe((loaded: boolean) => {
            this._parseCusto();
        });
    }

    /**
     * @function _parseCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _parseCusto(locale: string = null): void {
        this.CUSTO['thanksPageRedirectUrl'] = this._custoService.getProp('thanksPageRedirectUrl', locale);
        this.CUSTO['thanksPageFormUrl'] = this._custoService.getProp('thanksPageFormUrl', locale);
        if (this.CUSTO['thanksPageRedirectUrl'] || this.CUSTO['thanksPageFormUrl']) {
            this._hasExternalRedirection = true;
        }
    }

}
