export enum LogLevel {
    Off = 0,
    Error,
    Warning,
    Info,
    Debug
}

export type LogOutput = (source: string, level: LogLevel, ...objects: any[]) => void;

export class Logger {

    /**
     * Data members
     */
    static level = LogLevel.Debug;
    static outputs: LogOutput[] = [];

    /**
     * @function constructor
     * @param {string} source
     */
    constructor(
        private source?: string
    ) {}

    /**
     * @function enableProductionMode
     * @description Enables production mode.
     * Sets logging level to LogLevel.Warning.
     * @public
     * @returns {void}
     */
    public static enableProductionMode(): void {
        Logger.level = LogLevel.Debug;
    }

    /**
     * @function debug
     * @description Logs messages or objects with the debug level.
     * Works the same as console.log().
     * @public
     * @param {any[]} objects
     * @returns {void}
     */
    public debug(...objects: any[]): void {
        this.log(console.log, LogLevel.Debug, objects);
    }

    /**
     * @function info
     * @description Logs messages or objects with the info level.
     * Works the same as console.log().
     * @public
     * @param {any[]} objects
     * @returns {void}
     */
    public info(...objects: any[]): void {
        this.log(console.info, LogLevel.Info, objects);
    }

    /**
     * @function warn
     * @description Logs messages or objects with the warning level.
     * Works the same as console.log().
     * @public
     * @param {any[]} objects
     * @returns {void}
     */
    public warn(...objects: any[]): void {
        this.log(console.warn, LogLevel.Warning, objects);
    }

    /**
     * @function log
     * @description Logs messages or objects with the error level.
     * Works the same as console.log().
     * @public
     * @param {any[]} objects
     * @returns {void}
     */
    public error(...objects: any[]): void {
        this.log(console.error, LogLevel.Error, objects);
    }

    /**
     * @function log
     * @description
     * @private
     * @param {Function} func
     * @param {LogLevel} level
     * @param {any[]} objects
     * @returns {void}
     */
    private log(func: Function, level: LogLevel, objects: any[]): void {
        if (level <= Logger.level) {
            const log = this.source ? ['[' + this.source + ']'].concat(objects) : objects;
            func.apply(console, log);
            Logger.outputs.forEach((output) => output.apply(output, [this.source, level].concat(objects)));
        }
    }

}
