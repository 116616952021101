// Core modules
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// Third-party modules
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ResizeEvent} from 'angular-resizable-element';
import {BoundingRectangle} from 'angular-resizable-element/interfaces/bounding-rectangle.interface';

// Internal interfaces
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';
import {DisallowStreaming} from '@app/core/messaging/disallow-streaming';
import {MessageInterface} from '@app/core/messaging/message';
import {AllowStreaming} from '@app/core/messaging/allow-streaming';
// Internal models
import {Session} from '@app/shared/models/session';
// Internal services
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from '@app/home/session/presentation/presentation-events.service';
import {OpentokConnectionStatus, OpentokService} from '@app/home/session/conference/opentok.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {UtilService} from '@app/shared/service/util.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {Logger} from '@app/core/logger.service';
import {SessionService} from '@app/shared/service/session.service';

// Global variables declaration
const logger = new Logger('Opentok');

@Component({
    selector: 'app-conference',
    templateUrl: './conference.component.html',
    styleUrls: ['./conference.component.scss']
})
export class ConferenceComponent implements OnInit, OnDestroy {

    /**
     * Data members
     */
    @Input() session: Session;
    public canStream: boolean = false;
    public canResize: number = 0;
    public streamModal: boolean = false;
    private _translations: string[] = [];
    private subscriptions: Subscription[] = [];
    private _conferenceSession: OT.Session;
    private _isConferenceEnabled: boolean = false;
    private _videoParam: boolean = false;

    /**
     * @function constructor
     * @param {AuthenticationService} authService
     * @param {AuthenticationService} translateService
     * @param {OpentokService} opentokService
     * @param {FlashService} flashService
     * @param {PresentationEventsService} eventsService
     * @param {MessagingService} messagingService
     * @param {BrowserService} _browserService
     * @param {UtilService} _utilService
     */
    constructor(
        private authService: AuthenticationService,
        private translateService: TranslateService,
        private opentokService: OpentokService,
        private flashService: FlashService,
        private eventsService: PresentationEventsService,
        private messagingService: MessagingService,
        private _browserService: BrowserService,
        private _utilService: UtilService
    ) {
        this.translateService.get('not supported browser version for OpenTok')
            .subscribe((trans: string) => this._translations['not supported browser version for OpenTok'] = trans);

        if (this._browserService.isDesktop()) {
            this.canResize = 5;
        } else {
            this.canResize = 0;
        }

        // safari browser need param video set to true
        if (this._browserService.isSafari()) {
            this._videoParam = true;
        }

        this.subscriptions.push(
            this.eventsService.actionRequestsReplay.subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.isConferenceEnabled:
                            this._isConferenceEnabled = action.data;
                            if (this._isConferenceEnabled) {
                                this.checkMediaAccessibility();
                            }
                            this._toggleConferenceConnection(action.data);
                            break;
                    }
                })
        );

        this.subscriptions.push(
            this.eventsService.actionRequests.subscribe(
                (response: PresentationResponse) => {
                    switch (response.event) {
                        case PresentationEvent.ToggleMicro:
                            this.opentokService.muteUnmutePublisher();
                            if(this.opentokService.deniedAVAccess)
                                this.deniedAVFlash();
                            if(this.opentokService.isConnectionLost)
                                this.connectionIssueFlash()
                            break;

                        case PresentationEvent.ToggleCamera:
                            this.opentokService.togglePublisherVideo();
                            if(this.opentokService.deniedAVAccess)
                                this.deniedAVFlash();
                            if(this.opentokService.isConnectionLost)
                                this.connectionIssueFlash();
                            break;
                    }
                }
            )
        );

        this.subscriptions.push(this.messagingService.Messages
            .subscribe((message: MessageInterface) => {
                const credentials = this.authService.credentials;
                const usernameFiltered = credentials && credentials.username && credentials.username.match(/_(.*?)@/);
                const myUid = usernameFiltered && usernameFiltered.pop();
                switch (message.constructor) {
                    case DisallowStreaming:
                        if (message instanceof DisallowStreaming && message.streamerUid && message.streamerUid.split('@')[0] === myUid && this.canStream) {
                            this.eventsService.streamingDisabledNotify();
                            this.opentokService.stopPublishing();
                            this.streamNotificationModal();
                            this.canStream = false;
                        }
                        break;

                    case AllowStreaming:
                        if (message instanceof AllowStreaming && message.streamerUid && message.streamerUid.split('@')[0] === myUid && !this.canStream) {
                            this.eventsService.streamingAllowedNotify();
                            this.opentokService.startPublishing();
                            this.streamNotificationModal();
                            this.canStream = true;
                        }
                        break;

                    case ConferenceEnableCommand:
                        const isConference = <ConferenceEnableCommand>message;
                        this._isConferenceEnabled = isConference.isConferenceEnabled;
                        if (this._isConferenceEnabled) {
                            this.checkMediaAccessibility();
                        }
                        this.eventsService.conferenceCanConnect(this._isConferenceEnabled);
                        break;
                }
            })
        );

        this.subscriptions.push(
            this.opentokService.conferenceEvents.subscribe((status: OpentokConnectionStatus) => {
                switch (status) {
                    case OpentokConnectionStatus.Disabled:
                        this.canStream = false;
                        this.eventsService.streamingDisabledNotify();
                        break;

                    case OpentokConnectionStatus.Disconnected:
                        this.eventsService.streamingDisabledNotify();
                        this.canStream = false;
                        /* this.translateService.get("Stream has been disconnected.").subscribe(
                            ((txt: string) => {
                                this.flashService.warning(txt);
                            })
                        );*/
                        break;

                    case OpentokConnectionStatus.Reconnected:
                        if (!this.opentokService.deniedAVAccess)
                            this.translateService.get('You joined the audio/video conference.').subscribe(
                                ((txt: string) => {
                                    this.flashService.info(txt);
                                })
                            );
                        break;

                    case OpentokConnectionStatus.Reconnecting:
                        this.translateService.get('Trying to reconnect to the audio/video conference.').subscribe(
                            ((txt: string) => {
                                this.flashService.warning(txt,1000,false);
                            })
                        );
                        break;

                    case OpentokConnectionStatus.PublishingError:
                        this.canStream = false;
                        this.eventsService.streamingDisabledNotify();
                        this.translateService.get('Unable to start publishing.').subscribe(
                            ((txt: string) => {
                                this.flashService.error(txt);
                            })
                        );
                        break;

                    case OpentokConnectionStatus.Error:
                        this.canStream = false;
                        this.eventsService.streamingDisabledNotify();
                        this.translateService.get('Error connecting to the session.').subscribe(
                            ((txt: string) => {
                                this.flashService.error(txt);
                            })
                        );
                        break;
                    case OpentokConnectionStatus.DeniedAudioVideo:
                            this.deniedAVFlash();
                        break;

                    case OpentokConnectionStatus.ConnectionIssue:
                            this.connectionIssueFlash();
                        break;

                    case OpentokConnectionStatus.FirewallIssue:
                        this.firewallIssueFlash();
                        break;
                }
            })
        );
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        // check if the browser is able to run openTok
        if (this.opentokService.getOT().checkSystemRequirements()) {
            this.connectToConference();
        }
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this.opentokService.disable();
        this.eventsService.streamingDisabledNotify();
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
        // Resetting session & token objects from opentokService after destroying all above
        this.opentokService.resetSession();
    }

    /**
     * @function onResizeEnd
     * @description
     * @public
     * @param {ResizeEvent} event
     * @param {any} elemId
     * @returns {void}
     */
    public onResizeEnd(event: ResizeEvent, elemId: any): void {
        const elem: HTMLElement = document.getElementById(elemId);

        if (!elem) {
            return;
        }

        const rect = this.refreshVideoBounds(event.rectangle, elemId);

        // resize only for the desktop
        if (this._browserService.isDesktop()) {
            elem.style.width = rect.width + 'px';
            elem.style.height = rect.height + 'px';
            // update the height/width on resizing in desktop mode
            const firstChild = <HTMLElement>elem.firstChild;
            firstChild.style.height = '100%';
            firstChild.style.width = '100%';
        }

        if (rect === null) {
            return;
        }

        elem.style.left = rect.left + 'px';
        elem.style.top = rect.top + 'px';
        elem.style.position = 'fixed';
    }

    /**
     * @function checkMediaAccessibility
     * @description
     * @private
     * @returns {void}
     */
    private checkMediaAccessibility(): void {
        // Checking if the browser is able to run openTok on IE11
        if (this._browserService.isIE()) {
            if (!this.opentokService.getOT().checkSystemRequirements()) {
                this.opentokService.getOT().upgradeSystemRequirements();
            }
        }
    }

    /**
     * @function getStreams
     * @description
     * @public
     * @returns {OT.Stream[]}
     */
    public getStreams(): OT.Stream[] {
        return this.opentokService.streams;
    }

    /**
     * @function isPublishingVideo
     * @description
     * @public
     * @returns {boolean}
     */
    public isPublishingVideo(): boolean {
        if (this.opentokService.publisher && this.opentokService.publishing) {
            return this.opentokService.publisher.stream && this.opentokService.publisher.stream.hasVideo;
        }
    }

    /**
     * @function windowResized
     * @description
     * @public
     * @param {HTMLElement} streamDom
     * @returns {void}
     */
    public windowResized(streamDom: HTMLElement): void {
        const rect: BoundingRectangle = this.refreshVideoBounds({
            top: streamDom.offsetTop,
            left: streamDom.offsetLeft,
            right: 0,
            bottom: 0,
            width: streamDom.offsetWidth,
            height: streamDom.offsetHeight,
        });
        streamDom.style.left = rect.left + 'px';
        streamDom.style.top = rect.top + 'px';
        streamDom.style.position = 'fixed';
    }

    /**
     * @function streamNotificationModal
     * @description
     * @public
     * @returns {void}
     */
    public streamNotificationModal(): void {
        this.streamModal = true;
        setTimeout(() => {
            this.streamModal = false;
        }, 4000);
    }

    /**
     * @function connectToConference
     * @description
     * @private
     * @returns {void}
     */
    private connectToConference(): void {
        const key = SessionService.getSessionKey(this.session);
        this.opentokService.initSession(`${key}`, false).subscribe(
            (session: OT.Session) => {
                logger.info(session);
                this.eventsService.conferenceCanConnect(this._isConferenceEnabled);
            });
    }

    /**
     * @function refreshVideoBounds
     * @description
     * @private
     * @param {BoundingRectangle} rect
     * @param {elementId} elementId
     * @returns {BoundingRectangle}
     */
    private refreshVideoBounds(rect: BoundingRectangle, elementId: string = null): BoundingRectangle {
        if (rect.left < 0) {
            rect.left = 0;
        }

        if (rect.top < 0) {
            rect.top = 0;
        }

        if (rect.left > (window.innerWidth - rect.width)) {
            rect.left = window.innerWidth - rect.width;
        }

        if (rect.top > (window.innerHeight - rect.height)) {
            rect.top = window.innerHeight - rect.height;
        }

        // Avoiding other videos overlapping
        if (elementId) {
            const videos: HTMLCollectionOf<Element> = document.getElementsByClassName('kad-conference');

            for (let i = 0; i < videos.length; i++) {
                const rect1 = videos[i].getBoundingClientRect();

                if (videos[i].id === elementId) {
                    continue;
                }

                const overlap = !(rect1.right < rect.left ||
                    rect1.left > rect.right ||
                    rect1.bottom < rect.top ||
                    rect1.top > rect.bottom);

                if (overlap && !this._browserService.isDesktop()) {
                    return null;
                }
            }
        }

        return rect;
    }

    /**
     * @function _confenrenceConnect
     * @description
     * @private
     * @returns {void}
     */
    private _confenrenceConnect(): void {
        this.opentokService.connect().then((session: OT.Session) => {
            if(!this.opentokService.deniedAVAccess)
                this.translateService.get('You joined the audio/video conference.').subscribe(
                    (text: string) => {
                        logger.info(session);
                        this._conferenceSession = session;
                        this.flashService.info(text);
                    }
                );
        });
    }

    /**
     * @function _toggleConferenceConnection
     * @description
     * @private
     * @param {boolean} connect
     * @returns {void}
     */
    private _toggleConferenceConnection(connect: boolean): void {
        this.subscriptions.push(
            this.eventsService.actionRequestsReplay.subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.ConferenceCanConnect:
                        if (this._isConferenceEnabled && !this.canStream && !this._conferenceSession) {
                            if (this._browserService.isIE()) {
                                this._confenrenceConnect();
                            } else {
                                if (navigator.mediaDevices) {
                                    navigator.mediaDevices.getUserMedia({
                                        audio: true,
                                        video: this._videoParam
                                    }).then(() => {
                                        this.opentokService.deniedAVAccess = false;
                                        this._confenrenceConnect();
                                    }).catch(() => {
                                        this.opentokService.deniedAVAccess = true;
                                        this.deniedAVFlash();
                                    });

                                } else {
                                    const message = this._translations['not supported browser version for OpenTok'];
                                    this.flashService.warning(message, 5000);
                                }
                            }
                        } else if (!this._isConferenceEnabled && this.canStream || !this._isConferenceEnabled && this._conferenceSession) {
                            this.eventsService.streamingDisabledNotify();
                            this.opentokService.stopPublishing();
                            this.translateService.get('The audio/video conference has been stopped').subscribe(
                                (text: string) => {
                                    this._conferenceSession = null;
                                    this.flashService.warning(text);
                                    setTimeout(() => {
                                        this.opentokService.disable();
                                    }, 1000);
                                }
                            );
                        }
                        break;
                }
            })
        );
    }

    /**
     * @function Denied Audio Video access Flash Service
     */
    deniedAVFlash() {
        this.translateService.get('micro_camera_denied_notification').subscribe(
            ((txt: string) => {
                this.flashService.error(txt,99999999, true);
            })
        );
    }


    /**
     * @function Network Connection Flash Service
     */
    connectionIssueFlash() {
        this.translateService.get('network_connection_lost').subscribe(
            ((txt: string) => {
                this.flashService.error(txt,99999999, true);
            })
        );
    }


    /**
     * @function Firewall issue Flash Service
     */
    firewallIssueFlash() {
        this.translateService.get('firewall_issue').subscribe(
            ((txt: string) => {
                this.flashService.error(txt,99999999, true);
            })
        );
    }

}
