// Core modules
import {Component, EventEmitter, Input, Output} from '@angular/core';

// Internal models
import {Logger} from '@app/core/logger.service';
import {QuestionValues} from '@app/shared/models/question-values';
import {Answer} from '@app/shared/models/answer';

// Internal services
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

// Global variables declaration
const logger = new Logger('RateQuestionComponent');

@Component({
    selector: 'app-poll-rate',
    templateUrl: './rate.question.component.html',
    styleUrls: ['./rate.question.component.scss']
})
export class RateQuestionComponent {

    /**
     * Data members
     */
    @Output() questionAnswerEmitter = new EventEmitter();
    public nbAnswers: number = 0;
    public nbParticipants: number = 0;
    public rate: number = 0;
    public readOnly: boolean = false;
    public nbDontKnownAnswers: number = 0;
    public showAnswers: boolean = false;

    /**
     * @function constructor
     * @param {PresentationEventsService} _presentationActionsService
     */
    constructor(
        private _presentationActionsService: PresentationEventsService
    ) {}

    /**
     * @function Input
     * @param {Array<Answer>} answers
     */
    @Input()
    set answers(answers: Array<Answer>) {
        if (answers && answers.length > 0) {
            this.setAnswers(answers);
        }
    }

    /**
     * @function sendResponse
     * @description
     * @public
     * @param {number} value
     * @returns {void}
     */
    sendResponse(value: number): void {
        this._cleanButtonsClassnames();
        this.questionAnswerEmitter.emit(value);
    }

    /**
     * @function dontKnow
     * @description
     * @public
     * @param {any} $event
     * @returns {void}
     */
    public dontKnow($event: any): void {
        this._updateClassnames($event.target);
        this.rate = 0;
        this.questionAnswerEmitter.emit(QuestionValues.UNKNOWN);
    }

    /**
     * @function setAnswers
     * @description
     * @public
     * @param {Answer[]} answers
     * @returns {void}
     */
    public setAnswers(answers: Array<Answer>): void {
        this.nbAnswers = answers.length;
        /* @TODO: Number of participants of the Presentation is not sent by XMPP */

        this.rate = 0;
        for (const answer of answers) {
            if (answer.value !== -QuestionValues.UNKNOWN) {
                this.rate += answer.value;
            } else {
                this.nbDontKnownAnswers++;
            }
        }
        this.rate = this.rate / answers.length;
        this.readOnly = true;
        this.showAnswers = true;
    }

    /**
     * @function _cleanButtonsClassnames
     * @description
     * @private
     * @returns {void}
     */
    private _cleanButtonsClassnames(): void {
        const dontKnowBtnClassList = document.getElementById('dontKnowBtn').classList;
        dontKnowBtnClassList.remove('btn-tertiary');
        dontKnowBtnClassList.add('btn-alt');
    }

    /**
     * @function _updateClassnames
     * @description
     * @private
     * @param {any} elem
     * @returns {void}
     */
    private _updateClassnames(elem: any): void {
        // Cleaning all buttons classnames
        this._cleanButtonsClassnames();

        // Updating active button classnames
        elem.classList.remove('btn-alt');
        elem.classList.add('btn-tertiary');
    }

}
