// Core modules
import {Routes} from '@angular/router';

// Internal modules
import {AuthenticationGuard} from './authentication/authentication.guard';

// Internal components
import {ShellComponent} from '../shell/shell.component';

export class Route {

    /**
     * @function withShell
     * @description Creates routes using the shell component and authentication.
     * @param {Routes} routes
     * @returns {Routes}
     */
    public static withShell(routes: Routes): Routes {
        return [{
            path: '',
            component: ShellComponent,
            children: routes,
            canActivate: [AuthenticationGuard],
            // Reusing ShellComponent instance when navigating between child views
            data: { reuse: true }
        }];
    }

}
