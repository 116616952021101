// Core modules
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

// Internal services
import {AuthenticationService} from './authentication.service';
import {Logger} from '../logger.service';

// Global variables declaration
const logger = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

    /**
     * @function constructor
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     */
    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService
    ) {}

    /**
     * @function canActivate
     * @description
     * @public
     * @param route
     * @param state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._authenticationService.isAuthenticated()) {
            return true;
        }
        logger.debug('Not authenticated, redirecting...');
        this._router.navigate(['/login'], {replaceUrl: true});
        return false;
    }

}
