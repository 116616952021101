import {Participant} from '@app/shared/models/participant';

export interface Session {
    id: number;
    audioType: SessionAudioType;
    campaign: string;
    invitation: Array<string>;
    sessionKey: string;
    sessionName: string;
    sessionState: SessionState;
    sessionType: string;
    utcOffset: number;
    actualStartDate: string;
    expectedStartDate: string;
    lastScheduledTimestamp: string;
    expectedDuration: number;
    invitationTotalSent: 0;
    members: Participant[];
    description: string;
}

export enum SessionState {
    Started = 'STARTED',
    Paused = 'PAUSED',
    Scheduled = 'SCHEDULED',
    Updated = 'UPDATED',
    Canceled = 'CANCELED',
    Ended = 'ENDED',
}

export enum SessionAudioType {
    Isharing = 'ISHARING',
    None = 'NONE',
    External = 'EXTERNAL',
}

export enum SessionType {
    RemoteOCE = 'REMOTE_OCE',
    Remote = 'REMOTE'
}
