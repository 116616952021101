// Core modules
import {Component, OnDestroy, OnInit} from '@angular/core';

// Third-party modules
import {Subscription} from 'rxjs';

// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';
import {PresenterControl} from '@app/core/messaging/presenter-control';
import {InteractiveModeEnableCommand} from '@app/core/messaging/interactive-mode-enable-command';
import {HighlightingModeCommand} from '@app/core/messaging/highlighting-mode-command';

// Internal services
import {PresentationEvent, PresentationEventsService, PresentationResponse} from '@app/home/session/presentation/presentation-events.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {SdkService} from '@app/shared/service/sdk.service';

@Component({
    selector: 'app-presenter-control',
    templateUrl: './presenter-control.component.html',
    styleUrls: ['./presenter-control.component.scss']
})
export class PresenterControlComponent implements OnInit, OnDestroy {

    /**
     * Data members
     */
    public hasPresenterMode: boolean = false;
    public hasInteractiveMode: boolean;
    public askingForHand: boolean = false;
    public showGetControl: boolean = false;
    public showLostControl: boolean = false;
    public showGetInteractiveMode: boolean;
    public showLostInteractiveMode: boolean;
    private _subscription: Subscription[] = [];
    private sdk: any;

    /**
     * @function constructor
     * @param {PresentationEventsService} _eventsService
     * @param {MessagingService} _messagingService
     * @param {AuthenticationService} _authService
     * @param {SdkService} _sdkService
     */
    constructor(
        private _eventsService: PresentationEventsService,
        private _messagingService: MessagingService,
        private _authService: AuthenticationService,
        private _sdkService: SdkService
    ) {
        this._subscription.push(
            this._eventsService.actionRequests
                .subscribe(
                    (action: PresentationResponse) => {
                        switch (action.event) {
                            case PresentationEvent.askForHandNotification:
                                this._getHandAction(action.data);
                                break;
                            case PresentationEvent.ToWaitingRoom:
                                if (this.hasPresenterMode) {
                                    this._messagingService.leaveHand();
                                }
                                break;
                        }
                    }
                ));

        this._subscription.push(
            this._messagingService.Messages.subscribe((message: MessageInterface) => {
                switch (message.constructor) {
                    case PresenterControl:
                        const PresenterControlMessage = <PresenterControl>message;
                        let presenterID: string = PresenterControlMessage.presenterID.split('@')[0];
                        if (presenterID.indexOf('token_') !== -1) {
                            presenterID = presenterID.substring(6);
                        }
                        let participantID: string = this._authService.credentials.username.split('@')[0];
                        if (participantID.indexOf('token_') !== -1) {
                            participantID = participantID.substring(6);
                        }
                        // Activating presenter mode for the accepted participant only
                        // as the message is broadcast to everyone in the room
                        if (presenterID === participantID) {
                            this._handControl(PresenterControlMessage);
                        } else if (this.hasPresenterMode) {
                            this._handControl(PresenterControlMessage);
                        }
                        break;
                    case InteractiveModeEnableCommand:
                        this._toggleInteractiveMode(<InteractiveModeEnableCommand>message);
                        break;
                    case HighlightingModeCommand:
                        this._toggleHighlightingMode(<HighlightingModeCommand>message);
                        break;
                }
            }));

        this._subscription.push(
            this._messagingService.ReplayMessages.subscribe((message: MessageInterface) => {
                switch (message.constructor) {
                    case InteractiveModeEnableCommand:
                        this._toggleInteractiveMode(<InteractiveModeEnableCommand>message);
                        break;
                }
            }));
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.sdk = this._sdkService.sdk;
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this._subscription.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }

    /**
     * @function _handControl
     * @description
     * @private
     * @param {PresenterControl} message
     * @returns {void}
     */
    private _handControl(message: PresenterControl): void {
        if (!this.hasPresenterMode) {
            this.hasPresenterMode = true;
            this._getHandAction(this.hasPresenterMode);
        } else {
            this.hasPresenterMode = false;
            this._getHandAction(this.hasPresenterMode);
        }
        this._eventsService.isHandNotification(this.hasPresenterMode);
        this.sdk.getPlayerJS().eventDispatcher({'type': 'presenter-mode', 'status': this.hasPresenterMode});
    }

    /**
     * @function _toggleInteractiveMode
     * @description
     * @private
     * @param {InteractiveModeEnableCommand} message
     * @returns {void}
     */
    private _toggleInteractiveMode(message: InteractiveModeEnableCommand): void {
        this.hasInteractiveMode = message.isEnabled;
        this._toggleInteractiveModeAction(this.hasInteractiveMode);
        this._eventsService.interactiveModeStatusNotification(this.hasInteractiveMode);
        this._sdkService.injectScript().then(() => {
            if (!this.sdk) {
                this.sdk = this._sdkService.sdk;
            }
            this.sdk.getPlayerJS().eventDispatcher({'type': 'interactive-mode', 'status': this.hasInteractiveMode});
        });
    }

    /**
     * @function _toggleHighlightingMode
     * @description
     * @private
     * @param {HighlightingModeCommand} message
     * @returns {void}
     */
    private _toggleHighlightingMode(message: HighlightingModeCommand): void {
        this._sdkService.injectScript().then(() => {
            if (!this.sdk) {
                this.sdk = this._sdkService.sdk;
            }
            this._sdkService.sdk.getPlayerJS().eventDispatcher({
                type: 'drawing-mode',
                isEnabled: message.isEnabled,
                userID: this._authService.credentials.username
            });
        });
    }

    /**
     * @function _getHandAction
     * @description
     * @private
     * @param {boolean} hasPresenterMode
     * @returns {void}
     */
    private _getHandAction(hasPresenterMode: boolean): void {
        this.askingForHand = false;
        if (!hasPresenterMode) {
            // Displaying lost control icon
            this.showLostControl = true;
            setTimeout(() => {
                this.showLostControl = false;
            }, 3000);
        } else {
            // Displaying get control icon
            this.showGetControl = true;
            setTimeout(() => {
                this.showGetControl = false;
            }, 3000);
        }
    }

    /**
     * @function _toggleInteractiveModeAction
     * @description
     * @private
     * @param {boolean} hasInteractiveMode
     * @returns {void}
     */
    private _toggleInteractiveModeAction(hasInteractiveMode: boolean): void {
        if (!hasInteractiveMode) {
            this.showLostInteractiveMode = true;
            setTimeout(() => {
                this.showLostInteractiveMode = false;
            }, 3000);
        } else {
            this.showGetInteractiveMode = true;
            setTimeout(() => {
                this.showGetInteractiveMode = false;
            }, 3000);
        }
    }

}
