// Core modules
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

// Third-party modules
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// Internal modules
import {AppConfig} from '@app/core/app.config';

// Internal models
import {User} from '@app/shared/models/user';

@Injectable()
export class UserService {

    /**
     * @function constructor
     * @param {HttpClient} http
     * @param {AppConfig} config
     */
    constructor(
        private http: HttpClient,
        private config: AppConfig
    ) {}

    /**
     * @function getSessions
     * @description
     * @public
     * @param {string} uid
     * @returns {Observable<User>}
     */
    getUser(uid: string): Observable<User> {
        return this.http.get(this.config.get('serverApiUrl') + '/users/' + uid)
            .pipe(
                map((body: User) => body)
            );
    }

    /**
     * @function me
     * @description
     * @public
     * @returns {void}
     */
    me() {
        return this.http.get(this.config.get('serverApiUrl') + '/users/me')
            .pipe(
                map((body: User) => body)
            );
    }

}
