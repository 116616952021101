// Core modules
import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';

// Internal services
import {I18nService} from '@app/core/i18n.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {UtilService} from '@app/shared/service/util.service';
import {HeaderService} from '@app/shell/header/header.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    /**
     * Data members
     */
    @Input() show: boolean = true;
    public title: string = null;
    public hideBackButton: boolean = true;
    private _translations: string[] = [];
    private _isExternalUSer: boolean;

    /**
     * @function constructor
     * @param {Router} router
     * @param {HeaderService} headerService
     * @param {AuthenticationService} _authenticationService
     * @param {TranslateService} _translateService
     * @param {I18nService} i18nService
     * @param {UtilService} _utilService
     */
    constructor(
        private router: Router,
        private headerService: HeaderService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private i18nService: I18nService,
        private _utilService: UtilService
    ) {
        this.headerService.visibilityChanged.subscribe((data: boolean) => this.show = data);
        this.headerService.titleChanged.subscribe((data: string) => this.title = data);
        this.headerService.backButtonVisibilityChanged.subscribe((data: boolean) => this.hideBackButton = data);
        this._initTranslations();
        this._authenticationService.isExternalUser().then((bool: boolean) => {
            this._isExternalUSer = bool;
        });
    }

    /**
     * @function languages
     * @description
     * @public
     * @returns {string[]}
     */
    public get languages(): string[] {
        return this.i18nService.availableLocales;
    }

    /**
     * @function username
     * @description
     * @public
     * @returns {string|null}
     */
    public get username(): string | null {
        const credentials = this._authenticationService.credentials;
        return credentials ? credentials.username : null;
    }

    /**
     * @function backAction
     * @description
     * @public
     * @returns {void}
     */
    public backAction(): void {
        this.headerService.backButtonAction();
    }

    /**
     * @function isExternalUser
     * @public
     * @description
     * @returns {boolean}
     */
    public isExternalUser(): boolean {
        return this._isExternalUSer;
    }

    /**
     * @function logout
     * @public
     * @description
     * @returns {void}
     */
    public logout(): void {
        if (confirm(this._translations['leave_session_confirm'])) {
            this._utilService._doLogout('/login');
        }
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Are you sure you want to leave the presentation?')
            .subscribe((trans: string) => this._translations['leave_session_confirm'] = trans);
    }

}
