// Core modules
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    /**
     * @function transform
     * @description
     * @public
     * @param {any[]} items
     * @param {string} searchText
     * @return {any[]}
     */
    public transform(items: any[], searchText: string): any[] {

        function clearText(name: string) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = name;
            return tempDiv.innerText;
        }

        if (!items) {
            return [];
        }

        if (!searchText) {
            items.forEach((elt: any) => {
                elt.name = clearText(elt.name);
            });
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter((it: any) => {
            const plainText = clearText(it.name);
            if (plainText.toLowerCase().includes(searchText)) {
                const regEx = new RegExp(searchText, 'gi');
                // Got issue with filtered name property in original object used to get related content name
                // When opening related content, its name contains <span> tags due to the research,
                // and that causes a crash of some observables obviously
                // it.name = plainText.replace(regEx, match => `<span class="highlight">${match}</span>`);
                return it.name;
            }
        });
    }

}
