import { Injectable } from '@angular/core';
import { DynamicContent } from 'app/shared/models/dynamic_content/dynamic_content';
import { DynamicContentCustomer } from 'app/shared/models/dynamic_content/customer';
import { DynamicContentPresentation } from 'app/shared/models/dynamic_content/presentation';

@Injectable()
export class DynamicContentOCE implements DynamicContent {

    /*************/
    /*  MEMBERS  */
    /*************/

    /* private does not work ? */
    public presentationIndex: number;
    public slideIndex: number;
    public sequenceIndex: number;
    public presentations: DynamicContentPresentation[];
    public customers: DynamicContentCustomer[];
    public currentMode: string;
    public state: string;
    public parameters: any[];

    /*****************/
    /*  CONSTRUCTOR  */
    /*****************/

    constructor() {
        this.setPresentationIndex = null;
        this.setSlideIndex = null;
        this.setSequenceIndex = null;
        this.setPresentations = [];
        this.setCustomers = [];
        this.setCurrentMode = '';
        this.setState = '';
        this.setParameters = [];
    }

    /*************/
    /*  SETTERS  */
    /*************/

    /**
     * @function setPresentationIndex
     * @description Setter for presentationIndex
     * @public
     * @param {number} presentationIndex
     */
    set setPresentationIndex(presentationIndex: number) {
        this.presentationIndex = presentationIndex;
    }

    /**
     * @function setSlideIndex
     * @description Setter for slideIndex
     * @public
     * @param {number} slideIndex
     */
    set setSlideIndex(slideIndex: number) {
        this.slideIndex = slideIndex;
    }

    /**
     * @function setSequenceIndex
     * @description Setter for sequenceIndex
     * @public
     * @param {number} sequenceIndex
     */
    set setSequenceIndex(sequenceIndex: number) {
        this.sequenceIndex = sequenceIndex;
    }

    /**
     * @function setCurrentMode
     * @description Setter for currentMode
     * @public
     * @param {string} currentMode
     */
    set setCurrentMode(currentMode: string) {
        this.currentMode = currentMode;
    }

    /**
     * @function setState
     * @description Setter for state
     * @public
     * @param {string} state
     */
    set setState(state: string) {
        this.state = state;
    }

    /**
     * @function setParameters
     * @description Setter for parameters
     * @public
     * @param {any[]} parameters
     */
    set setParameters(parameters: any[]) {
        this.parameters = parameters;
    }

    /**
     * @function setPresentations
     * @description Setter for presentations
     * @public
     * @param {DynamicContentPresentation[]} presentations
     */
    set setPresentations(presentations: DynamicContentPresentation[]) {
        this.presentations = presentations;
    }

    /**
     * @function setCustomers
     * @description Setter for customers
     * @public
     * @param {DynamicContentCustomer[]} customers
     */
    set setCustomers(customers: DynamicContentCustomer[]) {
        this.customers = customers;
    }

    /*************/
    /*  GETTERS  */
    /*************/

    /**
     * @function getPresentationIndex
     * @description Getter for presentationIndex
     * @public
     * @returns {number}
     */
    get getPresentationIndex(): number {
        return this.presentationIndex;
    }

    /**
     * @function getSlideIndex
     * @description Getter for slideIndex
     * @public
     * @returns {number}
     */
    get getSlideIndex(): number {
        return this.slideIndex;
    }

    /**
     * @function getSequenceIndex
     * @description Getter for sequenceIndex
     * @public
     * @returns {number}
     */
    get getSequenceIndex(): number {
        return this.sequenceIndex;
    }

    /**
     * @function getCurrentMode
     * @description Getter for currentMode
     * @public
     * @returns {string}
     */
    get getCurrentMode(): string {
        return this.currentMode;
    }

    /**
     * @function getState
     * @description Getter for state
     * @public
     * @returns {string}
     */
    get getState(): string {
        return this.state;
    }

    /**
     * @function getParameters
     * @description Getter for parameters
     * @public
     * @returns {any[]}
     */
    get getParameters(): any[] {
        return this.parameters;
    }

    /**
     * @function getPresentations
     * @description Getter for presentations
     * @public
     * @returns {DynamicContentPresentation[]}
     */
    get getPresentations(): DynamicContentPresentation[] {
        return this.presentations;
    }

    /**
     * @function getCustomers
     * @description Getter for customers
     * @public
     * @returns {DynamicContentCustomer[]}
     */
    get getCustomers(): DynamicContentCustomer[] {
        return this.customers;
    }

}
