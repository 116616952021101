// Core modules
import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

    /**
     * @function constructor
     * @param {ElementRef} el
     */
    constructor(
        private el: ElementRef
    ) {}

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        this.el.nativeElement.focus();
    }

}
