// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {Subject} from 'rxjs';

// Internal modules
import {Flash, FlashType} from '@app/shared/flash/flash';

@Injectable()
export class FlashService {

    /**
     * Data members
     */
    private _flashes: Subject<Flash> = new Subject<Flash>();

    /**
     * @function constructor
     */
    constructor() {}

    /**
     * @function info
     * @description
     * @public
     * @param message
     * @param milliseconds
     * @param isForeground
     */
    public info(message: string, milliseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.INFO;
        flash.duration = milliseconds;
        flash.foreground = isForeground;
        this._flashes.next(flash);
    }

    /**
     * @function success
     * @description
     * @public
     * @param message
     * @param milliseconds
     * @param isForeground
     * @returns {void}
     */
    public success(message: string, milliseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.SUCCESS;
        flash.duration = milliseconds;
        flash.foreground = isForeground;
        this._flashes.next(flash);
    }

    /**
     * @function warning
     * @description
     * @public
     * @param message
     * @param milliseconds
     * @param isForeground
     * @returns {void}
     */
    public warning(message: string, milliseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.WARNING;
        flash.duration = milliseconds;
        flash.foreground = isForeground;
        this._flashes.next(flash);
    }

    /**
     * @function error
     * @description
     * @public
     * @param message
     * @param milliseconds
     * @param isForeground
     * @returns {void}
     */
    public error(message: string, milliseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.ERROR;
        flash.duration = milliseconds;
        flash.foreground = isForeground;
        this._flashes.next(flash);
    }

    /**
     * @function flashes
     * @description
     * @public
     * @return {Subject<Flash>}
     */
    get flashes(): Subject<Flash> {
        return this._flashes;
    }

}
