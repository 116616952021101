// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {Subject} from 'rxjs';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

@Injectable()
export class RelatedService {

    /**
     * Data members
     */
    public sharedRelatedOpeningSubject: Subject<string> = new Subject();
    public isInPresenterMode: boolean;
    private win: Window;

    /**
     * @function constructor
     */
    constructor(private _eventService: PresentationEventsService) {}

    /**
     * @function openInNewTab
     * @description
     * @public
     * @param {sting} url
     * @returns {void}
     */
    public openInNewTab(url: string): void {
        this.win = window.open(url, '_blank');

        if (this.win) {
            this.win.focus();
        }

        if (this.isInPresenterMode) {
            this._eventService.showExternalLinkCloseAction();
        }
    }

    public closeTab(): void {
        if (this.win) {
            this.win.close();
            this.win = null;
        }
    }

}
