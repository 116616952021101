// Core modules
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DecimalPipe} from '@angular/common';

// Internal models
import {Question} from '@app/shared/models/question';
import {Logger} from '@app/core/logger.service';
import {QuestionValues} from '@app/shared/models/question-values';
import {Answer} from '@app/shared/models/answer';

// Internal services
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

// Global variables declaration
const logger = new Logger('NumericQuestionComponent');

@Component({
    selector: 'app-poll-numeric',
    templateUrl: './numeric.question.component.html',
    styleUrls: ['./numeric.question.component.scss']
})
export class NumericQuestionComponent implements OnInit {

    /**
     * Data members
     */
    @Input() question: Question;
    @Output() questionAnswerEmitter = new EventEmitter();
    public binary: any;
    public participantsAnswers: any = {};
    public nbAnswers: number = 0;
    public nbParticipants: number = 0;
    public nbAnswersByValueArray: any[] = [];
    public stats: Array<number> = [];
    public nbDontKnownAnswers: number = 0;
    public showAnswers: boolean = false;

    /**
     * @function constructor
     * @param {PresentationEventsService} _presentationActionsService
     * @param {DecimalPipe} _decimalPipe
     */
    constructor(
        private _presentationActionsService: PresentationEventsService,
        private _decimalPipe: DecimalPipe
    ) {
        // Initializing Array of participants answers
        for (let i = 1; i <= 10; i++) {
            this.nbAnswersByValueArray.push({key: i, value: 0});
        }
    }

    /**
     * @function Input
     * @param {Answer[]>} answers
     */
    @Input()
    set answers(answers: Array<Answer>) {
        if (answers && answers.length > 0) {
            this.setAnswers(answers);
        }
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.setResultsMatrix(this.question.mask);
    }

    /**
     * @function setResultsMatrix
     * @description
     * @public
     * @param {number} mask
     * @returns {void}
     */
    setResultsMatrix(mask: number): void {
        this.binary = mask.toString(2);
        const formattedNumber = this._decimalPipe.transform(parseInt(this.binary, 10), '10.0-0');
        this.binary = formattedNumber.replace(/,/g, '').split('');
    }

    /**
     * @function sendResponse
     * @description
     * @public
     * @param {number} value
     * @param {any} $event
     * @returns {void}
     */
    public sendResponse(value: number, $event: any): void {
        this._updateClassnames($event.target);
        this.questionAnswerEmitter.emit(value);
    }

    /**
     * @function dontKnow
     * @description
     * @public
     * @param {any} $event
     * @returns {void}
     */
    public dontKnow($event: any): void {
        this._updateClassnames($event.target);
        this.questionAnswerEmitter.emit(QuestionValues.UNKNOWN);
    }

    /**
     * @function setAnswers
     * @description
     * @public
     * @param {Answer[]} answers
     * @returns {void}
     */
    public setAnswers(answers: Array<Answer>): void {
        this.nbAnswers = answers.length;
        /* @TODO: Number of participants of the Presentation is not sent by XMPP */

        for (const answer of answers) {
            const AnswerValue = answer.value;
            this.participantsAnswers[answer.ownerDN] = AnswerValue;
            if (AnswerValue === -1) {
                this.nbDontKnownAnswers++;
            } else {
                this.nbAnswersByValueArray.forEach((item: any) => {
                    if (item.key === AnswerValue) {
                        item.value++;
                    }
                });
            }
        }

        this.showAnswers = true;
    }

    /**
     * @function getNumBarsWithValue
     * @description
     * @public
     * @returns {number}
     */
    public getNumBarsWithValue(): number {
        return this.binary.filter((bin: string) => {
            return bin === '1';
        }).length;
    }

    /**
     * @function _cleanButtonsClassnames
     * @description
     * @private
     * @returns {void}
     */
    private _cleanButtonsClassnames(): void {
        for (let i = 1; i <= 10; i++) {
            const numericalBtnClassList = document.getElementById('num' + i + 'Btn').classList;
            numericalBtnClassList.remove('btn-tertiary');
            numericalBtnClassList.add('btn-alt');
        }
        const dontKnowBtnClassList = document.getElementById('dontKnowBtn').classList;
        dontKnowBtnClassList.remove('btn-tertiary');
        dontKnowBtnClassList.add('btn-alt');
    }

    /**
     * @function _updateClassnames
     * @description
     * @private
     * @param {any} elem
     * @returns {void}
     */
    private _updateClassnames(elem: any): void {
        // Cleaning all buttons classnames
        this._cleanButtonsClassnames();

        // Updating active button classnames
        elem.classList.remove('btn-alt');
        elem.classList.add('btn-tertiary');
    }

}
