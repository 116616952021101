// Core modules
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

    /**
     * @function constructor
     * @param {DomSanitizer} sanitizer
     */
    constructor(
        private sanitizer: DomSanitizer
    ) {}

    /**
     * @function transform
     * @description
     * @public
     * @param {string} url
     * @returns {any}
     */
    public transform(url: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
