// Core modules
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Internal components
import {HomeComponent} from './home.component';
import {PreCallTestToolComponent} from '@app/home/pre-call-test-tool/pre-call-test-tool.component';

// Internal services
import {Route} from '@app/core/route.service';
import {extract} from '@app/core/i18n.service';
import {PreCallTestToolGuard} from '@app/home/pre-call-test-tool/pre-call-test-tool.guard';

// Global variables declaration
const routes: Routes = Route.withShell([
    {path: '', redirectTo: '/sessions', pathMatch: 'full'},
    {
        path: 'sessions',
        component: HomeComponent,
        data: {title: extract('APP_NAME')},
        canActivateChild: [PreCallTestToolGuard],
        children: [
            {
                path: 'call-test',
                component: PreCallTestToolComponent
            }
        ]
    }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class HomeRoutingModule {}
