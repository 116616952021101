// Core modules
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResizableModule} from 'angular-resizable-element';

// Third-party modules
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// Internal components
import {LoaderComponent} from '@app/shared/loader/loader.component';
import {FlashComponent} from '@app/shared/flash/flash.component';
import {ToggleComponent} from './components/toggle/toggle.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';

// Internal services
import {FlashService} from '@app/shared/flash/flash.service';
import {SessionService} from '@app/shared/service/session.service';

// Internal directives
import {AutofocusDirective} from '@app/shared/directive/AutofocusDirective';
import {ClickOutsideDirective} from '@app/shared/directive/clickOutsideDirective';

// Internal pipes
import {SafeUrlPipe} from '@app/shared/pipes/safeUrl.pipe';
import {SafeHtmlPipe} from '@app/shared/pipes/safeHtml.pipe';
import {FilterPipe} from '@app/shared/pipes/filter.pipe';
import {FilterTitlePipe} from '@app/shared/pipes/filterTitle.pipe';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        ResizableModule
    ],
    declarations: [
        LoaderComponent,
        SafeUrlPipe,
        SafeHtmlPipe,
        FlashComponent,
        AutofocusDirective,
        ClickOutsideDirective,
        FilterPipe,
        FilterTitlePipe,
        ToggleComponent,
        ProgressBarComponent
    ],
    exports: [
        LoaderComponent,
        FlashComponent,
        SafeUrlPipe,
        SafeHtmlPipe,
        FilterPipe,
        FilterTitlePipe,
        ResizableModule,
        AutofocusDirective,
        ClickOutsideDirective,
        ToggleComponent,
        ProgressBarComponent
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                FlashService,
                SessionService
            ],
        }
    }
}
