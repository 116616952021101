// Core modules
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

// Internal models
import {Question} from '@app/shared/models/question';
import {PollResponse} from '@app/shared/models/poll_response';
import {PollService} from '@app/shared/service/poll.service';
import {Answer} from '@app/shared/models/answer';

// Internal services
import {Logger} from '@app/core/logger.service';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

// Global variables declaration
const logger = new Logger('Poll');

@Component({
    selector: 'app-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {

    /**
     * Data members
     */
    @Input() question: Question;
    @Input() surveyID: number;
    @Output() showPoll = new EventEmitter();
    public questionText: string;
    public pollResponse: PollResponse;
    public isValid: boolean = false;
    public showConfirmMessage: boolean = false;
    public showConfirmDispatchedResponse: boolean = false;
    public showQuestion: boolean = true;
    public answers: Array<Answer>;

    /**
     * @function constructor
     * @param {PollService} _pollService
     * @param {PresentationEventsService} _presentationActionsService
     */
    constructor(
        private _pollService: PollService,
        private _presentationActionsService: PresentationEventsService
    ) {}

    /**
     * @function pollAnswers
     * @param {Answer[]} pollAnswers
     */
    @Input('pollAnswers')
    set pollAnswers(pollAnswers: Array<Answer>) {
        if (pollAnswers && pollAnswers.length > 0) {
            this.answers = pollAnswers;
            this.showConfirmMessage = false;
            this.showQuestion = false;
        }
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.questionText = this.question['label'];
    }

    /**
     * @function submitPoll
     * @description
     * @public
     * @returns {void}
     */
    public submitPoll(): void {
        this._pollService.postResponse(this.pollResponse, this.surveyID).subscribe(
            (answer: Answer) => {
                this.confirmMessage();
            },
            (err: any) => console.error(err)
        );
    }

    /**
     * @function confirmMessage
     * @description
     * @public
     * @returns {void}
     */
    public confirmMessage(): void {
        this.showConfirmMessage = true;
        this.showQuestion = false;
    }

    /**
     * @function questionResponse
     * @description
     * @public
     * @param {number} answer
     * @returns {void}
     */
    public questionResponse(answer: number) {
        this.pollResponse = new PollResponse();
        this.pollResponse.value = [];
        this.pollResponse.surveyid = this.surveyID;
        this.pollResponse.value.push(answer);
        this.isValid = true;
    }

    /**
     * @function closePoll
     * @description
     * @public
     * @returns {void}
     */
    public closePoll(): void {
        this._presentationActionsService.closePollAction();
    }

}
