// Internal interfaces
import {Message} from '@app/core/messaging/message';

export class Presence extends Message {

    uid: string;
    user: {
        jid: string,
        role: string,
        affiliation: string
    };

}
