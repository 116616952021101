// Internal interfaces
import {Message} from '@app/core/messaging/message';
import {ScreenSize} from '@app/core/messaging/screen-size';
import {VideoAction} from '@app/core/messaging/video-action';

export class CurrentSlide extends Message {

    sequenceIdentifier: string;
    presentationIdentifier: string;
    sender: string;
    slideName: string;
    slideURL: string;
    size: ScreenSize;
    dynamicContent: string;
    timestamp: number;
    videos: VideoAction[];
    animation: string;

}
