// Core modules
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// Third-party modules
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AlertModule} from 'ngx-bootstrap';

// Internal modules
import {SharedModule} from '@app/shared/shared.module';
import {ThanksPageRoutingModule} from './thanks-page-routing.module';
import {ThanksPageComponent} from './thanks-page.component';
import {ThanksPageService} from '@app/thanks-page/thanks-page.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbModule,
        AlertModule.forRoot(),
        ThanksPageRoutingModule,
        SharedModule
    ],
    declarations: [
        ThanksPageComponent
    ],
    providers: [
        ThanksPageService
    ]
})
export class ThanksPageModule {
}
