// Core modules
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SafeResourceUrl} from '@angular/platform-browser';

// Third-party modules
import {MessagingService} from '@app/core/messaging/messaging.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

// Internal models
import {CommentBox, CommentBoxActionType, CommentBoxColor} from '@app/core/messaging/comment-box';

// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';
import {LoadSequence, LoadSlide} from '@app/core/messaging/load-slide';

@Component({
    selector: 'app-comment-box',
    templateUrl: './comment-box.component.html',
    styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit {

    /**
     * Data members
     */
    @ViewChild('commentHolder') commentHolder: ElementRef;
    @Input() scaleCss: string;
    public commentBoxes: CommentBox[] = [];
    public subscriptions: Subscription[] = [];
    private currentSlide: SafeResourceUrl;
    private commentBoxPaddings = {
        headerHeight: 39, // 37 + 2
        headerWidthCB: 1, // 2 * 2
        paddingCB: 10, // 2 * 5
        borderCB: 4
    };

    /**
     * @function constructor
     * @param {MessagingService} messagingService
     */
    constructor(
        private messagingService: MessagingService
    ) {
        this.subscriptions.push(
            this.messagingService.Messages
                .pipe(filter((message: MessageInterface) => message ! instanceof CommentBox || message ! instanceof LoadSlide))
                .subscribe((message: MessageInterface) => {
                    if (message instanceof CommentBox) {
                        this.commentBoxeMessageReceived(message);
                    }
                    if (message instanceof LoadSequence) {
                        this.currentSlide = message.sequenceURL;
                    }
                })
        );
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        setTimeout(() => {
            this.commentHolder.nativeElement.style = this.scaleCss;
        }, 500);
    }

    /**
     * @function getCssStyle
     * @description
     * @public
     * @param commentBox
     * @returns {any}
     */
    public getCssStyle(commentBox: CommentBox): any {
        const yCB = Math.round(commentBox.y - commentBox.height / 2 + this.commentBoxPaddings.headerHeight);
        const xCB = Math.round(commentBox.x - commentBox.width / 2 + this.commentBoxPaddings.headerWidthCB);
        const widthCB = Math.round(commentBox.width - this.commentBoxPaddings.paddingCB - this.commentBoxPaddings.headerWidthCB - this.commentBoxPaddings.borderCB);
        const heightCB = Math.round(commentBox.height - this.commentBoxPaddings.paddingCB - this.commentBoxPaddings.headerHeight - this.commentBoxPaddings.borderCB);
        const css: {} = {
            width: widthCB + 'px',
            height: heightCB + 'px',
            left: xCB + 'px',
            top: yCB + 'px',
        };
        return css;
    }

    /**
     * @function getFiltredCommentBoxes
     * @description
     * @public
     * @returns {any}
     */
    public getFiltredCommentBoxes() {
        return this.commentBoxes.filter((val) => val.anchor === this.currentSlide);
    }

    /**
     * @function getCssClass
     * @description
     * @public
     * @param {CommentBox} commentBox
     * @returns {string}
     */
    public getCssClass(commentBox: CommentBox): string {
        switch (commentBox.style) {
            case CommentBoxColor.Black:
                return 'black';
            case CommentBoxColor.BlackWithOpacity:
                return 'black-opacity';
            case CommentBoxColor.OrangeWithOpacity:
                return 'orange-opacity';
            case CommentBoxColor.WhiteWithOpacity:
                return 'white-opacity';
            case CommentBoxColor.White:
                return 'white';
            case CommentBoxColor.Blue:
                return 'blue';
            case CommentBoxColor.Green:
                return 'green';
            case CommentBoxColor.Yellow:
                return 'yellow';
        }
        return 'yellow';
    }

    /**
     * @function commentBoxeMessageReceived
     * @description
     * @public
     * @param {CommentBox} commentBox
     * @returns {void}
     */
    private commentBoxeMessageReceived(commentBox: CommentBox): void {
        const index = this._getCommentBoxIndex(commentBox.oid);
        if (index < 0 && commentBox.actionType === CommentBoxActionType.Create) {
            this.commentBoxes.push(commentBox);
            return;
        }
        if (commentBox.actionType === CommentBoxActionType.Delete) {
            this.commentBoxes.splice(index, 1);
            return;
        }
        this.commentBoxes[index] = commentBox;
    }

    /**
     * @function _getCommentBoxIndex
     * @description
     * @private
     * @param {string} commentBoxId
     * @returns {number}
     */
    private _getCommentBoxIndex(commentBoxId: string): number {
        for (let i = 0; i < this.commentBoxes.length; i++) {
            if (this.commentBoxes[i].oid === commentBoxId) {
                return i;
            }
        }
        return -1;
    }

}
