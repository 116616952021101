// Internal interfaces
import {Message} from '@app/core/messaging/message';
import {SessionState} from '@app/shared/models/session';

export class Notification extends Message {

    state: SessionState;
    sessionId: number;

}
