// Core modules
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

// Internal models
import {QuestionValuesBoolean} from '@app/shared/models/question_values_boolean';
import {QuestionValues} from '@app/shared/models/question-values';
import {Answer} from '@app/shared/models/answer';

// Internal services
import {Logger} from '@app/core/logger.service';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

// Global variables declaration
const logger = new Logger('BooleanQuestionComponent');

@Component({
    selector: 'app-poll-boolean',
    templateUrl: './boolean.question.component.html',
    styleUrls: ['./boolean.question.component.scss']
})
export class BooleanQuestionComponent {

    /**
     * Data members
     */
    @Output() questionAnswerEmitter = new EventEmitter();
    @ViewChild('yesBtn') yesBtn: ElementRef;
    @ViewChild('noBtn') noBtn: ElementRef;
    @ViewChild('dontKnowBtn') dontKnowBtn: ElementRef;
    public nbAnswers: number = 0;
    public nbYesAnswers: number = 0;
    public nbNoAnswers: number = 0;
    public nbDontKnownAnswers: number = 0;
    public nbParticipants: number = 0;
    public percentageYes: number = 0;
    public percentageNo: number = 0;
    public percentageDontKnow: number = 0;
    public showAnswers: boolean = false;
    public YES_VALUE: number = QuestionValuesBoolean.YES_VALUE;
    public NO_VALUE: number = QuestionValuesBoolean.NO_VALUE;
    public UNKNOWN: number = QuestionValues.UNKNOWN;

    /**
     * @function constructor
     * @param {PresentationEventsService} _presentationActionsService
     */
    constructor(
        private _presentationActionsService: PresentationEventsService
    ) {}

    /**
     * @function answers
     * @param {Array<any>} answers
     */
    @Input()
    set answers(answers: Array<any>) {
        if (answers && answers.length > 0) {
            this.setAnswers(answers);
        }
    }

    /**
     * @function sendResponse
     * @description
     * @public
     * @param {QuestionValues} value
     * @param {any} $event
     * @returns {void}
     */
    public sendResponse(value: QuestionValues, $event: any): void {
        this._updateClassnames($event.target);
        this.questionAnswerEmitter.emit(value);
    }

    /**
     * @function setAnswers
     * @description
     * @public
     * @param {Answer[]} answers
     * @returns {void}
     */
    public setAnswers(answers: Array<Answer>): void {
        this.nbAnswers = answers.length;
        /* @TODO: Number of participants of the Presentation is not sent by XMPP */

        for (const answer of answers) {
            switch (answer.value) {
                case this.YES_VALUE:
                    this.nbYesAnswers++;
                    break;
                case this.NO_VALUE:
                    this.nbNoAnswers++;
                    break;
                case this.UNKNOWN:
                    this.nbDontKnownAnswers++;
                    break;
            }
        }

        this.percentageYes = 100 * (this.nbYesAnswers / this.nbAnswers);
        this.percentageNo = 100 * (this.nbNoAnswers / this.nbAnswers);
        this.percentageDontKnow = 100 * (this.nbDontKnownAnswers / this.nbAnswers);

        this.showAnswers = true;
    }

    /**
     * @function _cleanButtonsClassnames
     * @description
     * @private
     * @returns {void}
     */
    private _cleanButtonsClassnames(): void {
        const buttonsLabelArray: string[] = ['yes', 'no', 'dontKnow'];
        buttonsLabelArray.forEach((label: string) => {
            const classList = this[label + 'Btn'].nativeElement.classList;
            classList.remove('btn-tertiary');
            classList.add('btn-alt');
        });
    }

    /**
     * @function _updateClassnames
     * @description
     * @private
     * @param {any} elem
     * @returns {void}
     */
    private _updateClassnames(elem: any): void {
        // Cleaning all buttons classnames
        this._cleanButtonsClassnames();

        // Updating active button classnames
        elem.classList.remove('btn-alt');
        elem.classList.add('btn-tertiary');
    }

}
