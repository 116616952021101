// Internal interfaces
import {Message} from '@app/core/messaging/message';
import {User} from '@app/shared/models/user';

export class ChatMessage extends Message {

    timestamp: number;
    username: string;
    message: string;
    user: User;
    nickname: string;

}
