export class Flash {
    public type: FlashType;
    public message: string;
    public duration: number = 5000;
    public foreground: boolean;
}

export enum FlashType {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'danger',
    WARNING = 'warning'
}
