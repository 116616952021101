// Core modules
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

    /**
     * @function constructor
     * @param {DomSanitizer} sanitizer
     */
    constructor(
        private sanitizer: DomSanitizer
    ) {}

    /**
     * @function transform
     * @description
     * @public
     * @param {string} value
     * @returns {any}
     */
    public transform(value: string): any {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

}
