// Core modules
import { Injectable } from '@angular/core';

// Third-party modules
import {Subject} from 'rxjs';

@Injectable()
export class HeaderService {

  public titleChanged: Subject<string> = new Subject<string>();
  public visibilityChanged: Subject<boolean> = new Subject<boolean>();
  public backButtonVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  public backButtonActionChanged: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  changeTitle(title: string) {
    this.titleChanged.next(title);
  }

  changeVisibilty(isVisible: boolean) {
    this.visibilityChanged.next(isVisible);
  }

  backButtonVisibility(isVisible: boolean) {
    this.backButtonVisibilityChanged.next(isVisible);
  }

  backButtonAction() {
    this.backButtonActionChanged.next();
  }

}
