// Internal interfaces
import {Message} from '@app/core/messaging/message';

export class PollAnswers extends Message {

    status: string;
    answers: Array<any>;
    id: number;
    questionID: number;

}
