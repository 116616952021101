import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree, Router} from '@angular/router';
import {SessionStatusService} from '@app/shared/service/sessionStatus.service';
import {statePlayPause} from '@app/core/messaging/session-state';

@Injectable()
export class PreCallTestToolGuard implements CanActivateChild {
    constructor(private router: Router,
                private _sessionStatusService: SessionStatusService) {
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean | UrlTree {
        if (!this._sessionStatusService.sessionStatus) {
            return this.router.parseUrl('/sessions');
        }

        return this._sessionStatusService.sessionStatus.state === statePlayPause.pause;
    }

}
